import { createContext, useContext } from 'react'
import {
  PatientGenderIdentity,
  PatientLegalSex,
  TaskServiceType,
  PatientRelationshipToInsured,
  PatientContactRelation,
  type CreatePatientRegistrationSubmission,
  type UpdatePatientRegistrationSubmission,
  InsuranceOrdinality,
  BillingInsuredInput,
  STATES,
  PatientContactUse,
  ReferrerInformation,
  DayOfWeek,
  FileCreate,
  type TocConfiguration,
  InsurancePlanExternal,
  PatientOnboardingTaskType,
} from '@valerahealth/rtk-query'
import {
  dateToISODateStr,
  getTimezoneValues,
  dateToStr,
  differenceInDays,
  SYSTEM_TIMEZONE,
  getUSTimezone,
} from '@valerahealth/ui-components/utils/date'
import { fileToInput } from '@valerahealth/ui-components/form'

import { type FileInputValueType } from '@valerahealth/ui-components/form'
import {
  parseIntlPhoneNumber,
  setIntlPhoneNumber,
  trimStringsRecursive,
} from '@valerahealth/ui-components'
import { getI18n } from '@valerahealth/ui-translation'
import { Language } from 'locales'

/** used to share the sendOTP result accross components - https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#usemutation */
export const PAT_REG_SEND_OTP_CACHE_KEY = 'PAT_REG_SEND_OTP_CACHE_KEY'

export const formSpacing = 2

const FormContext = createContext<{ formType: 'patient-self' | 'referral' }>({
  formType: 'patient-self',
})
export const PatientFormContextProvider = FormContext.Provider
export const usePatientFormContext = () => useContext(FormContext)

export enum DischargeType {
  fromED = 'fromED',
  fromPsych = 'fromPsych',
}

export type Address = {
  line1: string
  line2: string
  city: string
  state: string
  postalCode: string
}

export type InsurancePlanSelectionType = InsurancePlanExternal & {
  _inputValue?: string
}

export type PatientInsuranceType = {
  relationship: PatientRelationshipToInsured | null
  firstName: string
  lastName: string
  middleInitial: string
  dateOfBirth: Date | null
  legalSex: PatientLegalSex | null
  insurancePlan: InsurancePlanSelectionType | null
  outOfNetworkPlanName: string
  idNumber: string
  policyGroupFecaNumber: string
  cardFront: FileInputValueType | null
  cardBack: FileInputValueType | null
  // medicaidIdNumber: string
  // medicaidCard: FileInputValueType | null
  // medicareIdNumber: string
  // medicareCard: FileInputValueType | null
}

export type PatientOnboardingReferralFormPiece = {
  referralSourceOther: string
  referrer: ReferrerInformation
  dischargeSummary: FileInputValueType | null
  dischargeType: DischargeType | null
  dischargeDate: Date | null
  additionalTaskDescription: {
    // These are displayed as is in the task description so giving them readable key names
    'Reason for Admission': string
    'Discharge Diagnosis': string
    'Discharge Medications': string
  }
  documents: FileInputValueType[]
  preferredContactDaysOfWeek: DayOfWeek[]
  conditionInterferingWithVirtualTreatment: boolean | null
  treatmentHistory: string
  preferredLanguage: string
}

export type PatientOnboardingFormType = {
  _meta: {
    // form only fields that do not get submitted to server
    otp: string[]
    serviceAgreementViewed: boolean
    primaryInsurance: {
      payerId: string | null
      failPanelVerifyConfirmed: boolean
    }
    secondaryInsurance: {
      payerId: string | null
      failPanelVerifyConfirmed: boolean
    }
    tertiaryInsurance: {
      payerId: string | null
      failPanelVerifyConfirmed: boolean
    }
  }
  submissionMeta?: Record<string, string>
  firstName: string
  lastName: string
  preferredName: string
  dateOfBirth: null | Date
  gender: PatientGenderIdentity | null
  genderDetails: string
  guardian: {
    firstName: string
    lastName: string
  } | null
  legalSex: PatientLegalSex | null
  phoneNumber: string
  email: string
  address: Address
  specialty: string[]
  serviceType: TaskServiceType[]
  tellUsMore: string
  hospitalized: boolean | null
  idFile: FileInputValueType | null
  primaryInsurance: PatientInsuranceType | null
  secondaryInsurance: PatientInsuranceType | null
  tertiaryInsurance: PatientInsuranceType | null
  patientTimeZone: string
  preferredContactTimes: string[]
  referralSource: string | null
} & Partial<PatientOnboardingReferralFormPiece>

type PatientOnboardingFormJSONType = Omit<
  PatientOnboardingFormType,
  | 'dateOfBirth'
  | 'primaryInsurance'
  | 'secondaryInsurance'
  | 'tertiaryInsurance'
  | 'dischargeDate'
> & {
  dateOfBirth: null | string
  primaryInsurance:
    | (Omit<PatientInsuranceType, 'dateOfBirth'> & {
        dateOfBirth: null | string
      })
    | null
  secondaryInsurance:
    | (Omit<PatientInsuranceType, 'dateOfBirth'> & {
        dateOfBirth: null | string
      })
    | null
  tertiaryInsurance:
    | (Omit<PatientInsuranceType, 'dateOfBirth'> & {
        dateOfBirth: null | string
      })
    | null
  dischargeDate?: string | null
}

const key = 'onboarding-form-state'
export const timezoneOptions = getTimezoneValues()
export const tocTimezoneOptions = timezoneOptions.filter(
  (v) => v.value === 'America/Phoenix' || v.value === 'America/New_York',
)

export function persistForm(values: PatientOnboardingFormType) {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(values))
  } catch (e) {
    console.error(e)
  }
}

export const defaultInsurance: PatientInsuranceType = {
  relationship: null,
  firstName: '',
  lastName: '',
  middleInitial: '',
  dateOfBirth: null,
  legalSex: null,
  insurancePlan: null,
  outOfNetworkPlanName: '',
  idNumber: '',
  policyGroupFecaNumber: '',
  cardFront: null,
  cardBack: null,
}
export const defaultFormValue: PatientOnboardingFormType = {
  _meta: {
    otp: ['', '', '', ''],
    serviceAgreementViewed: false,
    primaryInsurance: {
      payerId: null,
      failPanelVerifyConfirmed: false,
    },
    secondaryInsurance: {
      payerId: null,
      failPanelVerifyConfirmed: false,
    },
    tertiaryInsurance: { payerId: null, failPanelVerifyConfirmed: false },
  },
  firstName: '',
  lastName: '',
  preferredName: '',
  email: '',
  dateOfBirth: null,
  phoneNumber: '',
  gender: null,
  genderDetails: '',
  guardian: null,
  legalSex: null,
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
  },
  specialty: [],
  serviceType: [],
  tellUsMore: '',
  hospitalized: null,
  idFile: null,
  primaryInsurance: defaultInsurance,
  secondaryInsurance: null,
  tertiaryInsurance: null,
  patientTimeZone: getUSTimezone(SYSTEM_TIMEZONE),
  preferredContactTimes: [],
  referralSource: null,
}

export const defaultReferralFormPiece: PatientOnboardingReferralFormPiece = {
  referralSourceOther: '',
  referrer: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  dischargeSummary: null,
  dischargeType: null,
  dischargeDate: null,
  additionalTaskDescription: {
    'Reason for Admission': '',
    'Discharge Diagnosis': '',
    'Discharge Medications': '',
  },
  documents: [],
  preferredContactDaysOfWeek: [],
  conditionInterferingWithVirtualTreatment: null,
  treatmentHistory: '',
  preferredLanguage: 'English',
}

export function rehydrateForm(
  type: 'patient-self' | 'referral' = 'patient-self',
): PatientOnboardingFormType {
  const persisted = window.sessionStorage.getItem(key)
  try {
    if (!persisted) throw new Error()
    const {
      dateOfBirth,
      phoneNumber: _phoneNumber,
      _meta,
      primaryInsurance,
      secondaryInsurance,
      tertiaryInsurance,
      dischargeDate,
      ...form
    } = JSON.parse(persisted) as PatientOnboardingFormJSONType

    const phoneNumber = parseIntlPhoneNumber(_phoneNumber)

    return {
      ...defaultFormValue,
      ...(type === 'referral' ? defaultReferralFormPiece : null),
      ...form,
      _meta: {
        ...defaultFormValue._meta,
        ..._meta,
        otp: defaultFormValue._meta.otp,
      }, // always reset otp
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : null,
      phoneNumber,
      primaryInsurance: primaryInsurance && {
        ...defaultFormValue.primaryInsurance,
        ...primaryInsurance,
        dateOfBirth: primaryInsurance.dateOfBirth
          ? new Date(primaryInsurance.dateOfBirth)
          : null,
      },
      secondaryInsurance: secondaryInsurance && {
        ...defaultFormValue.secondaryInsurance,
        ...secondaryInsurance,
        dateOfBirth: secondaryInsurance.dateOfBirth
          ? new Date(secondaryInsurance.dateOfBirth)
          : null,
      },
      tertiaryInsurance: tertiaryInsurance && {
        ...defaultFormValue.tertiaryInsurance,
        ...tertiaryInsurance,
        dateOfBirth: tertiaryInsurance.dateOfBirth
          ? new Date(tertiaryInsurance.dateOfBirth)
          : null,
      },
      dischargeDate: dischargeDate
        ? new Date(dischargeDate)
        : typeof dischargeDate === 'string'
        ? undefined
        : dischargeDate,
    }
  } catch (e) {
    return {
      ...defaultFormValue,
      ...(type === 'referral' ? defaultReferralFormPiece : null),
    }
  }
}

type TocKeys = keyof Omit<TocConfiguration, 'referralSourceFilter'>

export const TOC_MAPPING: Record<
  TocKeys,
  { days: 7 | 30; dischargeType: DischargeType }
> = {
  fuh7: {
    days: 7,
    dischargeType: DischargeType.fromPsych,
  },
  fuh30: {
    days: 30,
    dischargeType: DischargeType.fromPsych,
  },
  fum7: {
    days: 7,
    dischargeType: DischargeType.fromED,
  },
  fum30: {
    days: 30,
    dischargeType: DischargeType.fromED,
  },
}

export const isTocQualified = ({
  tocConfiguration,
  referralSource,
  dischargeDate,
  dischargeType,
}: {
  tocConfiguration?: TocConfiguration[] | null
  referralSource?: string | null
  dischargeDate?: Date | null
  dischargeType?: DischargeType | null
}) => {
  if (!tocConfiguration?.length || !dischargeDate || !dischargeType)
    return false

  const daysSinceDischarge = differenceInDays(new Date(), dischargeDate)

  return tocConfiguration.some(({ referralSourceFilter, ...rest }) => {
    // If referralSourceFilter, make sure it matches the patient referral source
    if (referralSourceFilter && referralSourceFilter !== referralSource)
      return false

    // for any of the configurations, check if turned on, type matches, and discharge date is less than required
    return Object.entries(rest).some(([key, enabled]) => {
      const toc = TOC_MAPPING[key as keyof typeof rest]
      return (
        enabled &&
        daysSinceDischarge <= toc.days &&
        toc.dischargeType === dischargeType
      )
    })
  })
}

export function clearPersistedForm() {
  window.sessionStorage.removeItem(key)
}

export function formToCreatePatient(
  _data: PatientOnboardingFormType,
  isFinalSubmission?: boolean,
): CreatePatientRegistrationSubmission {
  const {
    // values that need some level of processing
    _meta: _,
    submissionMeta,
    preferredName,
    email,
    phoneNumber: _phoneNumber,
    dateOfBirth,
    gender,
    genderDetails,
    legalSex,
    guardian,
    address: { line1, line2, city, state, postalCode },
    specialty,
    serviceType,
    idFile,
    primaryInsurance,
    secondaryInsurance,
    tertiaryInsurance,
    tellUsMore,
    preferredLanguage,
    dischargeDate,
    dischargeType,
    documents,
    dischargeSummary,
    treatmentHistory,
    referralSource,
    referralSourceOther,
    preferredContactDaysOfWeek,
    hospitalized,
    additionalTaskDescription,
    ...rest
  } = trimStringsRecursive(_data)

  const phoneNumber = setIntlPhoneNumber(_phoneNumber)

  function insToBillingInsured(
    type: InsuranceOrdinality,
    {
      relationship,
      firstName,
      lastName,
      middleInitial,
      dateOfBirth: insDateOfBirth,
      legalSex,
      insurancePlan,
      outOfNetworkPlanName,
      idNumber,
      policyGroupFecaNumber,
      cardFront,
      cardBack,
    }: PatientInsuranceType,
  ): BillingInsuredInput {
    return {
      type,
      cardFront: isFinalSubmission ? fileToInput(cardFront) : undefined,
      cardBack: isFinalSubmission ? fileToInput(cardBack) : undefined,
      relationship: relationship || PatientRelationshipToInsured.Self,
      firstName: firstName || rest.firstName,
      lastName: lastName || rest.lastName,
      middleInitial: middleInitial || undefined,
      dateOfBirth: dateToISODateStr(insDateOfBirth || dateOfBirth),
      legalSex,
      idNumber,
      policyGroupFecaNumber,
      insurancePlanId:
        insurancePlan?._id === '-1' ? undefined : insurancePlan?._id,
      outOfNetwork: !!outOfNetworkPlanName,
      outOfNetworkPlan: outOfNetworkPlanName
        ? {
            name: outOfNetworkPlanName,
            state: {
              code: state,
              name: STATES.find((s) => s.code === state)?.name || '',
            },
          }
        : undefined,
    }
  }

  const insurances = (
    [
      [InsuranceOrdinality.Primary, primaryInsurance],
      [InsuranceOrdinality.Secondary, secondaryInsurance],
      [InsuranceOrdinality.Tertiary, tertiaryInsurance],
    ] as const
  ).filter(
    (v): v is [InsuranceOrdinality, PatientInsuranceType] => !!v[1]?.idNumber,
  )
  const selfPay = !insurances.length

  const isToc =
    isTocQualified({
      referralSource,
      dischargeDate,
      dischargeType,
      tocConfiguration: primaryInsurance?.insurancePlan?.tocConfiguration,
    }) ||
    isTocQualified({
      referralSource,
      dischargeDate,
      dischargeType,
      tocConfiguration: secondaryInsurance?.insurancePlan?.tocConfiguration,
    }) ||
    isTocQualified({
      referralSource,
      dischargeDate,
      dischargeType,
      tocConfiguration: tertiaryInsurance?.insurancePlan?.tocConfiguration,
    })

  return {
    preferredName: preferredName || undefined,
    dob: dateToStr(dateOfBirth),
    gender: gender || undefined,
    genderDetails: genderDetails || undefined,
    legalSex: legalSex!,
    phoneNumber,
    email,
    preferredLanguage:
      preferredLanguage ||
      {
        [Language.en]: 'English',
        [Language.es]: 'Spanish',
        [Language.zhCHS]: 'Chinese',
        [Language.zhTW]: 'Chinese',
      }[getI18n().resolvedLanguage],
    contacts: guardian
      ? [
          {
            relation: PatientContactRelation.ParentGuardian,
            firstName: guardian.firstName,
            lastName: guardian.lastName,
            email,
            phone: phoneNumber,
            use: PatientContactUse.Emergency,
            primary: true,
          },
        ]
      : undefined,
    address: {
      street: [line1, line2].filter((v) => v).join(', '),
      city,
      state,
      zipcode: postalCode,
    },
    specialty,
    serviceType,
    billing: {
      selfPay,
      idFile: isFinalSubmission ? fileToInput(idFile) : undefined,
      insurances: insurances.map(([ordinal, ins]) =>
        insToBillingInsured(ordinal, ins),
      ),
    },
    dischargeDate: dischargeDate && dateToStr(dischargeDate),
    tellUsMore: tellUsMore || undefined,
    dischargeType: dischargeType
      ? {
          [DischargeType.fromED]: 'From ED',
          [DischargeType.fromPsych]: 'From InpatientPsychiatric Hospital',
        }[dischargeType]
      : undefined,
    hospitalized,
    additionalTaskDescription:
      hospitalized && additionalTaskDescription
        ? JSON.stringify(
            Object.fromEntries(
              Object.entries(additionalTaskDescription).map(([key, value]) => [
                key,
                value || 'N/A',
              ]),
            ),
          )
        : undefined,
    treatmentHistory: treatmentHistory || undefined,
    referralSource: referralSource || undefined,
    referralSourceOther: referralSourceOther || undefined,
    preferredContactDaysOfWeek: preferredContactDaysOfWeek?.length
      ? preferredContactDaysOfWeek
      : undefined,
    documents:
      isFinalSubmission && (documents || dischargeSummary)
        ? (documents || [])
            .concat(dischargeSummary ? [dischargeSummary] : [])
            .map((v) => ({
              file: fileToInput(v) as FileCreate,
            }))
        : undefined,
    taskTypeOverride: isToc
      ? PatientOnboardingTaskType.NewPatientToc
      : undefined,
    meta: submissionMeta ? JSON.stringify(submissionMeta) : undefined,
    ...rest,
  }
}

export function formToUpdatePatient(
  submissionId: string,
  form: PatientOnboardingFormType,
  /** files only get submitted at the end so that we dont try to re-transfer files that are no longer in our /tmp upload bucket */
  isFinalSubmission = false,
): UpdatePatientRegistrationSubmission {
  return {
    submissionId,
    ...formToCreatePatient(form, isFinalSubmission),
  }
}

export function getIniFrame() {
  if (window.location !== window.parent.location) {
    return true
  }
  return false
}

export const NewYorkTimeZone = 'America/New_York'
